<app-header1></app-header1>

<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-box" class="ai2html">

    
    <!-- Artboard: SSW -->
	<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-SSW" class="g-artboard" style=""
		data-aspect-ratio="1.778" data-min-width="0">
		<div style="padding: 0 0 56.25% 0;"></div>
		<img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-SSW-img" class="g-aiImg" alt=""
			src="assets/img/Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-SSW.jpg" />
		<div id="g-ai7-1" class="g-Ebene_1 g-aiAbs"
			style="top:-1.2963%;left:49.2488%;margin-left:-25.2344%;width:50.4687%;">
			<p class="g-pstyle0">Schwangerschaft </p>
			<p class="g-pstyle0 display-none480"> & Stillzeit</p>
		</div>
		<div id="g-ai7-2" class="g-Ebene_1 g-aiAbs" style="top:30.2778%;left:20.8739%;width:61.7188%;">
			<p class="display-none"><span class="g-cstyle0">Die ersten 1000 Tage</span> im Leben deines Kindes gelten
				als besonders sensible und können bereits einen großen Einfluss auf die spätere Gesundheit haben. Denn
				bei dem Entstehungsrisiko für spätere, nicht ansteckende Krankheiten kann durch einen geeigneten
				Lebensstil entgegengewirkt werden. Deshalb ist es besonders in der Schwangerschaft wichtig sich gesund
				und ausgewogen zu ernähren um den Mehrbedarf an Nährstoffen zu decken.</p>
			<p class="display-none">&nbsp;</p>
			<p class="g-pstyle1">Mögliche Beratungsthemen: </p>
			<p>&nbsp;</p>
			<p>- Grundlagen gesunder / ausgewogener Ernährung</p>
			<p>- Nährstoffe & geeignete Lebensmittel für Schwangerschaft / Stillzeit</p>
			<p>- Nahrungsmittelergänzung in der Schwangerschaft? Ja? Nein?</p>
			<p class="display-none480">- Gewichtsreduktion</p>
			<p class="display-none480">- spezielle Ernährungsformen, wie vegetarisch, vegan etc.</p>
			<p class="display-none768 display-none600">- Ernährung bei Zöliakie, Unverträglichkeiten wie Fructose,
				Lactoseintoleranz</p>
			<p class="display-none768 display-none600">- Schwangerschaftsbeschwerden, wie Übelkeit, Erbrechen,
				Sodbrennen, Verstopfungen etc. </p>
			<p class="g-pstyle1"><span class="g-cstyle1">- </span>...vieles mehr </p>
		</div>
		<div id="g-ai7-3" class="g-Ebene_1 g-aiAbs g-aiPointText"
			style="top:83.1985%;margin-top:-65.5px;left:30.0878%;width:871px;">
			<p class="g-pstyle2 display-none768 display-none600">Individuelle Einzelberatung inklusive Anamnese &
				Auswertung Ernährungsprotokoll</p>
			<p class="g-pstyle2">Erstberatungsdauer: 60 Minuten</p>
			<p class="g-pstyle2">Folgeberatungsdauer: 45 Minuten</p>
			<p class="g-pstyle2">Beratungshonorar: nur ab 87&euro;</p>
		</div>
		<div id="g-ai7-4" class="g-Ebene_1 g-aiAbs"
			style="top:84.537%;left:72.8385%;margin-left:-6.5885%;width:13.1771%;">
			<a class="g-pstyle3" routerLink='/contactform' routerLinkActive="active"
				[routerLinkActiveOptions]="{exact: true}">Termin anfragen</a>
		</div>

	</div>
</div>