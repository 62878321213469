<app-header1></app-header1>
<!--====== Banner part start ======-->

<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-box" class="ai2html">


	<!-- Artboard: Kleinkinder -->
	<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Kleinkinder" class="g-artboard" style=""
		data-aspect-ratio="1.778" data-min-width="0">
		<div style="padding: 0 0 56.25% 0;"></div>
		<img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Kleinkinder-img" class="g-aiImg" alt=""
			src="assets/img/Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Kleinkinder.jpg" />
		<div id="g-ai9-1" class="g-Ebene_1 g-aiAbs"
			style="top:-1.2037%;left:50.024%;margin-left:-25.2344%;width:50.4687%;">
			<p class="g-pstyle0">Kleinkindernährung</p>
		</div>
		<div id="g-ai9-2" class="g-Ebene_1 g-aiAbs" style="top:30.8333%;left:20.868%;width:66.0417%;">
			<p class="g-pstyle1 display-none">Jede Familie und jedes Kind is(s)t anders. </p>
			<p class="display-none">In meiner Beratung bekommt jede Familie eine individuelle angepasste
				Ernährungsumstellung zusammen mit einer kompetenten Beratung. In der
				Phase des selbstständig Werdens sollte das Kind nicht blockiert werden, deshalb orientieren sich die
				Ernährungsumstellung und die Beratung an der Entwicklung und den Bedürfnissen deines Kindes.</p>
			<p>&nbsp;</p>
			<p class="g-pstyle2">Mögliche Beratungsthemen: </p>
			<p>&nbsp;</p>
			<p>- geeignete Lebensmittelauswahl</p>
			<p>- Kinderlebensmittel</p>
			<p>- Fragestellungen: Isst mein Kind genug? Picky-eater?</p>
			<p class="display-none480">- Übergewicht</p>
			<p class="display-none768 display-none600">- Ernährung bei Zöliakie, Unverträglichkeiten wie Fructose,
				Lactoseintoleranz</p>
			<p class="display-none480">- Beschwerden, wie Verstopfungen, Blähungen, Durchfall etc. </p>
			<p class="g-pstyle2"><span class="g-cstyle0">- </span>...vieles mehr </p>
		</div>
		<div id="g-ai9-3" class="g-Ebene_1 g-aiAbs g-aiPointText"
			style="top:84.217%;margin-top:-65.5px;left:28.8378%;width:871px;">
			<p class="g-pstyle3 display-none">Individuelle Einzelberatung inklusive Anamnese & Auswertung
				Ernährungsprotokoll</p>
			<p class="g-pstyle3">Erstberatungsdauer: 60 Minuten</p>
			<p class="g-pstyle3">Folgeberatungsdauer: 45 Minuten</p>
			<p class="g-pstyle3">Beratungshonorar: nur ab 87&euro;</p>
		</div>
		<div id="g-ai9-4" class="g-Ebene_1 g-aiAbs"
			style="top:88.5185%;left:73.3177%;margin-left:-6.5885%;width:13.1771%;">
			<a class="g-pstyle4" routerLink='/contactform' routerLinkActive="active"
				[routerLinkActiveOptions]="{exact: true}">Termin anfragen</a>
		</div>
		<div id="g-ai9-5" class="g-Ebene_1 g-aiAbs" style="top:97.7778%;left:50%;margin-left:-25.2344%;width:50.4687%;">
			<p class="g-pstyle0">aktuelle Kurse buchen</p>
		</div>
	</div>
</div>