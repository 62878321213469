import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Homepage1Component } from './components/pages/homepage1/homepage1.component';
import { Homepage2Component } from './components/pages/homepage2/homepage2.component';
import { Homepage3Component } from './components/pages/homepage3/homepage3.component';
import { AboutusComponent } from './components/pages/aboutus/aboutus.component';
import { CareerComponent } from './components/pages/career/career.component';
import { Service1Component } from './components/pages/service1/service1.component';
import { Service2Component } from './components/pages/service2/service2.component';
import { Service3Component } from './components/pages/service3/service3.component';
import { ServiceDetailComponent } from './components/pages/service-detail/service-detail.component';
import { Portfolio1Component } from './components/pages/portfolio1/portfolio1.component';
import { Portfolio2Component } from './components/pages/portfolio2/portfolio2.component';
import { PortfolioDetailComponent } from './components/pages/portfolio-detail/portfolio-detail.component';
import { ContactusComponent } from './components/pages/contactus/contactus.component';
import { ContactformComponent } from './contactform/contactform.component';
import { Service4Component } from './components/pages/service4/service4.component';
import { Service5Component } from './components/pages/service5/service5.component';
import { Service6Component } from './components/pages/service6/service6.component';
import { ImpressumComponent } from './components/pages/impressum/impressum.component';
import { DatenschutzComponent } from './components/pages/datenschutz/datenschutz.component';

const routes: Routes = [

  { path: '', component: Homepage1Component },
  { path: 'homepage2', component: Homepage2Component },
  { path: 'homepage3', component: Homepage3Component },
  { path: 'about-us', component: AboutusComponent },
  { path: 'career', component: CareerComponent },
  { path: 'service-one', component: Service1Component },
  { path: 'service-two', component: Service2Component },
  { path: 'service-three', component: Service3Component },
  { path: 'service-four', component: Service4Component },
  { path: 'service-five', component: Service5Component },
  { path: 'service-six', component: Service6Component },
  { path: 'service-detail', component: ServiceDetailComponent },
  { path: 'portfolio-one', component: Portfolio1Component },
  { path: 'portfolio-two', component: Portfolio2Component },
  { path: 'portfolio-detail', component: PortfolioDetailComponent },
  { path: 'contact-us', component: ContactusComponent },
  { path: 'contactform', component: ContactformComponent },
  { path: 'impressum', component: ImpressumComponent },
  { path: 'datenschutz', component: DatenschutzComponent }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
