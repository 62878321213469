<app-header1></app-header1>
<!--====== Banner part start ======-->

<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-box" class="ai2html">



	<!-- Artboard: _ber_mich_2 -->
	<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Krankenkasse" class="g-artboard" style=""
		data-aspect-ratio="1.778" data-min-width="0">
		<div style="padding: 0 0 56.25% 0;"></div>
		<img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Krankenkasse-img" class="g-aiImg" alt=""
			src="assets/img/Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Krankenkasse.png" />
		<div id="g-ai5-1" class="g-Ebene_1 g-aiAbs"
			style="top:-4.0741%;left:49.2448%;margin-left:-42.9948%;width:85.9896%;">
			<p class="g-pstyle0"></p>
			<p class="g-pstyle0">Impressum</p>
		</div>
		<div id="g-ai5-2" class="g-Ebene_1 g-aiAbs" style="top:31.0185%;left:19.8438%;width:64.7917%;">
			<p class="g-pstyle2">Angaben gemäß § 5 TMG </p>
			<p>&nbsp;</p>
			<p>Katharina Richter<br>

				Diätassistentin Katharina Richter<br>

				Richard- Wagner- Straße 11<br>

				85221 Dachau<br>

			</p>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
			<p class="g-pstyle2 display-none">Kontakt</p>
			<p class="display-none">&nbsp;</p>
			<p> Telefon: 01748209687<br>

				E-Mail: Kontakt@Richter-Ernährungsberatung.de<br></p>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
			<p class="g-pstyle2">Aufsichtsbehörde</p>
			<p>&nbsp;</p>
			<p>Gesundheitsamt<br>

				Dachau Dr.-Hiller-Str. 36<br>

				85221 Dachau<br>

				https://www.landratsamt-dachau.de/gesundheit-veterinaerwesen-sicherheitsrecht/gesundheit/gesundheitsamt/

			</p>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
		</div>

	</div>
	<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Krankenkasse" class="g-artboard" style=""
		data-aspect-ratio="1.778" data-min-width="0">
		<div style="padding: 0 0 56.25% 0;"></div>
		<img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Krankenkasse-img" class="g-aiImg" alt=""
			src="assets/img/impressum.jpg" />

		<div id="g-ai5-2" class="g-Ebene_1 g-aiAbs" style="top:4.0185%;left:19.8438%;width:64.7917%;">
			<p class="g-pstyle2">Berufsbezeichnung und berufsrechtliche Regelungen </p>
			<p>&nbsp;</p>

			<p> Berufsbezeichnung: Diätassistentin<br>


				Zuständige Kammer: Regierung von Oberbayern<br>

				Verliehen durch: Deutschland<br>

				Es gelten folgende berufsrechtliche Regelungen: Diätassistentingesetz Regelungen einsehbar unter:
				https://www.vdd.de/diaetassistenten/richtlinien-und-gesetze/<br></p>

			<p>&nbsp;</p>
			<p>&nbsp;</p>
			<p class="g-pstyle2">Streitschlichtung</p>
			<p>&nbsp;</p>

			<p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
				https://ec.europa.eu/consumers/odr. <br>
				Unsere E-Mail-Adresse finden Sie oben im Impressum.Wir sind nicht bereit oder verpflichtet, <br>
				an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.<br></p>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
			<p class="g-pstyle2">Haftung für Inhalte </p>
			<p>&nbsp;</p>

			<p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
				allgemeinen Gesetzen verantwortlich.<br>
				Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder<br>
				gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine<br>
				rechtswidrige Tätigkeit hinweisen.Verpflichtungen zur Entfernung oder Sperrung der Nutzung von<br>
				Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung
				ist<br>
				jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.<br>
				Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.<br>
			</p>
		</div>

	</div>
	<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Krankenkasse" class="g-artboard" style=""
		data-aspect-ratio="1.778" data-min-width="0">
		<div style="padding: 0 0 56.25% 0;"></div>
		<img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Krankenkasse-img" class="g-aiImg" alt=""
			src="assets/img/impressum2.png" />

		<div id="g-ai5-2" class="g-Ebene_1 g-aiAbs" style="top:4.0185%;left:19.8438%;width:64.7917%;">


			<p>&nbsp;</p>
			<p>&nbsp;</p>
			<p class="g-pstyle2">Haftung für Links </p>
			<p>&nbsp;</p>

			<p>
				Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss
				haben.<br>
				Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.<br>
				Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
				verantwortlich.<br>
				Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.<br>
				Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.Eine permanente inhaltliche
				Kontrolle der verlinkten Seiten<br>
				ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. <br>
				Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
			</p>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
			<p class="g-pstyle2">Urheberrecht</p>
			<p>&nbsp;</p>

			<p>
				Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
				Urheberrecht.<br>
				Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des
				Urheberrechtes bedürfen<br>
				der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
				sind nur für den privaten,<br>
				nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
				wurden, <br>
				werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
				gekennzeichnet. <br>
				Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
				entsprechenden Hinweis. <br>
				Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.


			</p>
		</div>

	</div>
</div>