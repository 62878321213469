<div class="sidebar">
  <!-- Services List Widget -->
  <div class="widget cat-widget">
    <h4 class="widget-title">All Service List</h4>

    <ul>
      <li>
        <a href="javascript:void(0)">Financial Planning <span><i class="far fa-angle-right"></i></span></a>
      </li>
      <li>
        <a href="javascript:void(0)">Relationship Buildup <span><i class="far fa-angle-right"></i></span></a>
      </li>
      <li>
        <a href="javascript:void(0)">Investement Planning <span><i class="far fa-angle-right"></i></span></a>
      </li>
      <li>
        <a href="javascript:void(0)">Marketing Strategy <span><i class="far fa-angle-right"></i></span></a>
      </li>
      <li>
        <a href="javascript:void(0)">Product Strategy <span><i class="far fa-angle-right"></i></span></a>
      </li>
    </ul>
  </div>

  <!-- CTA Widget -->
  <div class="widget cta-widget" style="background-image: url(assets/img/sidebar/cta.jpg);">
    <h4 class="title">Need Any Consultations</h4>
    <a href="javascript:void(0)" class="main-btn">Send Message</a>
  </div>
</div>
