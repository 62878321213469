
<app-header1></app-header1>
<!--====== Banner part start ======-->

<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-box" class="ai2html">
	<!-- Artboard: K_hlschrank -->
	<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-K_hlschrank" class="g-artboard" style=""
		data-aspect-ratio="1.778" data-min-width="0">
		<div style="padding: 0 0 56.25% 0;"></div>
		<img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-K_hlschrank-img" class="g-aiImg" alt=""
			src="assets/img/Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-K_hlschrank.jpg" />
		<div id="g-ai11-1" class="g-Ebene_1 g-aiAbs"
			style="top:-2.037%;left:50.5253%;margin-left:-33.8542%;width:67.7083%;">
			<p class="g-pstyle0">Kühlschrankcheck & </p>
			<p class="g-pstyle0">Einkaufscoaching</p>
		</div>
		<div id="g-ai11-2" class="g-Ebene_1 g-aiAbs" style="top:29.2593%;left:55.6822%;width:29.7396%;">
			<p class="g-pstyle1">Einkaufscoaching</p>
			<p class="">Gemeinsam gehen wir deinen Kühlschrank, sowie deine Vorratsschränke durch, dabei
				schauen wir uns die von dir</p>
			<p class="display-none600">bevorzugten Lebensmittel mithilfe der Zutatenliste genau an.</p>
			<p class="display-none768 display-none600"> Im Anschluss zeige ich dir Möglichkeiten auf, wie du deine
				Lebensmittelauswahl zugunsten einer ausgewogenen Ernährung verbessern kannst.</p>
		</div>
		<div id="g-ai11-3" class="g-Ebene_1 g-aiAbs" style="top:29.2593%;left:22.7215%;width:29.7396%;">
			<p class="g-pstyle1">Kühlschrankcheck</p>
			<p class="">Die Supermärkte haben ein immer größer werdendes Sortiment, aber welche Produkte
				sind denn nun empfehlenswert oder für eine ausgewogene Ernährung geeignet? </p>
			<p class="display-none600">Bei einem gemeinsamen Rundgang durch deine</p>
			<p class="display-none768 display-none600">Lieblingssupermärkte zeige ich dir zudem auf was du in Zukunft
				achten solltest.</p>
			<p class="display-none">&nbsp;</p>
			<p class="display-none">Doch die Planung deiner Ernährung beginnt bereits beim Einkaufszettel, bei einer
				Ernährungberatung, können wir die für dich passende Ernährung finden.</p>
		</div>
		<div id="g-ai11-4" class="g-Ebene_1 g-aiAbs g-aiPointText"
			style="top:60.3281%;margin-top:-50.5px;left:63.0423%;width:330px;">
			<p class="g-pstyle2">Einkaufscoaching:</p>
			<p class="g-pstyle2">Erstberatungsdauer: 60 Minuten</p>
			<p class="g-pstyle2">Beratungshonorar: nur ab 99&euro;</p>
		</div>
		<div id="g-ai11-5" class="g-Ebene_1 g-aiAbs g-aiPointText"
			style="top:71.8096%;margin-top:-50.5px;left:30.1572%;width:330px;">
			<p class="g-pstyle2">Kühlschrankcheck: </p>
			<p class="g-pstyle2">Erstberatungsdauer: 15 Minuten</p>
			<p class="g-pstyle2">Beratungshonorar: nur ab 29&euro;</p>
		</div>
		<div id="g-ai11-6" class="g-Ebene_1 g-aiAbs"
			style="top:74.8148%;left:73.3698%;margin-left:-6.5885%;width:13.1771%;">
			<a class="g-pstyle3" routerLink='/contactform' routerLinkActive="active"
				[routerLinkActiveOptions]="{exact: true}">Termin anfragen</a>
		</div>
		<div id="g-ai11-7" class="g-Ebene_1 g-aiAbs"
			style="top:98.1481%;left:48.7747%;margin-left:-25.2344%;width:50.4687%;">
			<p class="g-pstyle0">Vorträge buchen</p>
		</div>
	</div>
</div>