<app-header1></app-header1>
<!--====== Banner part start ======-->

<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-box" class="ai2html">


	<!-- Artboard: _ber_mich_1 -->
	<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-_ber_mich_1" class="g-artboard" style=""
		data-aspect-ratio="1.778" data-min-width="0">
		<div class="custom-height"></div>
		<img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-_ber_mich_1-img" class="g-aiImg" alt=""
			src="assets/img/Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-_ber_mich_1.jpg" />
		<div id="g-ai1-1" class="g-Ebene_1 g-aiAbs"
			style="top:-2.5%;left:61.3575%;margin-left:-25.2344%;width:50.4687%;">
			<p class="g-pstyle0">Über Katharina</p>
		</div>
		<div id="g-ai1-2" class="g-Ebene_1 g-aiAbs" style="top:24.6296%;left:41.6667%;width:42.1875%;">
			<p class="willkimmen-m-left"> -Willkommen, </p>
			<p>&nbsp;</p>
			<p>mein Name ist <span class="g-cstyle0">Katharina Richter</span> und ich bin staatlich <span
					class="g-cstyle1">anerkannte Diätassistentin.</span></p>
			<p>&nbsp;</p>
			<p>Ich konnte meine große Leidenschaft zu meiner Berufung machen. </p>
			<p>Meine Herzensangelegenheit besteht daraus, Dir in deinen prägenden
				Lebensabschnitten zur Seite zu stehen, ebenso möchte ich Dich inspirieren neues zu wagen. </p>
			<p class="custom-width-p">Bereits während meiner Ausbildung kristallisierten sich drei Lebensphasen heraus,
				auf die ich mich nun speziali-siert habe: </p>
			<div class="custom-float-left">
				<p class="g-pstyle1">Schwangerschaft & Stillzeit <span class="g-cstyle2">I</span> Säuglingsernährung
					<span class="g-cstyle2">I</span>
				</p>
				<p class="g-pstyle1">Kleinkindernährung </p>
			</div>
			<p>&nbsp;</p>
			<p class="custom-width-p display-none">Mein besonderes Interesse wurde dabei durch den Einfluss der
				Ernährung auf Mutter
				und Kind sowie dessen Auswirkungen auf die Gesundheit geweckt. Das daraus entstandene Interesse sowie
				meine große Neugierde nach Neuem, ließen mich selbst diverse Ernährungsvarianten ausprobieren. Denn bei
				einer gesunden Ernährung gibt es nicht die Eine Lösung für alle. </p>

		</div>
		<div id="g-ai1-3" class="g-Ebene_1 g-aiAbs" style="top:NaN%;left:41.6349%;width:29.2708%;">
			<p>&nbsp;</p>
			<p>Bereits während meiner Ausbildung kristallisierten sich drei Lebensphasen heraus, auf die ich mich nun
				spezialisiert habe:</p>
			<p>&nbsp;</p>
			<p class="g-pstyle1">Schwangerschaft & Stillzeit <span class="g-cstyle2">I</span> Säuglingsernährung <span
					class="g-cstyle2">I</span></p>
			<p class="g-pstyle1">Kleinkindernährung </p>
		</div>
		<div id="g-ai1-4" class="g-Ebene_1 g-aiAbs" style="top:NaN%;left:41.6719%;width:29.4792%;">
			<p>&nbsp;</p>
			<p class="g-pstyle2">Mein besonderes Interesse wurde dabei durch den </p>
			<p class="g-pstyle2">Einfluss der Ernährung auf Mutter und Kind sowie dessen Auswirkungen auf die Gesundheit
				geweckt. Das daraus entstandene Interesse sowie meine große Neugierde nach Neuem, ließen mich selbst
				diverse Ernährungsvarianten ausprobieren. </p>
			<p class="g-pstyle2">Denn bei einer gesunden Ernährung gibt es nicht die Eine Lösung für alle. </p>
		</div>
	</div>

	<!-- Artboard: _ber_mich_2 -->
	<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-_ber_mich_2" class="g-artboard" style=""
		data-aspect-ratio="1.778" data-min-width="0">
		<div style="padding: 0 0 56.25% 0;"></div>
		<img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-_ber_mich_2-img"
			class="g-aiImg custom-img3" alt=""
			src="assets/img/Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-_ber_mich_2.jpg" />
		<div id="g-ai2-1" class="g-Ebene_1 g-aiAbs" style="top:7.4074%;left:41.6913%;width:34.7396%;">
			<p class="g-pstyle0">Aus diesem Grund möchte Ich gemeinsam mit Dir eine individuell angepasste
				Ernährungsform für Dich finden.</p>
			<p>&nbsp;</p>
			<p class="custom-m768">Nachdem ich November 2019 selbst Mutter geworden bin, </p>
			<p>ist mir durch den Austausch mit anderen schwangeren Frauen</p>
			<p>sowie Müttern erst bewusst geworden, wie viele Mythen rund um die Ernährung in
				der <span class="g-cstyle0">Schwangerschaft & Stillzeit </span><span class="g-cstyle1">I</span><span
					class="g-cstyle0"> Säuglingsernährung </span><span class="g-cstyle1">I </span><span
					class="g-cstyle0">Kleinkindernährung</span>.</p>
			<div class="custom-mp_2">
				<p>Deshalb möchte ich Eltern die Unsicherheiten und Ängste</p>
				<p>bei der Ernährung nehmen, Euch über eine gesunde Ernährung aufklären, sowie Euch dabei
					unterstützen
					diese in Euren eigenen, individuellen Alltag zu integrieren.</p>
			</div>
			<p>Eine ganzheitliche Beratung ist mir dabei besonders wichtig.</p>
			<p>&nbsp;</p>
			<div class="custom-mp_2">
				<p>Du möchtest noch mehr über mich, meinen Beruf und meinen Alltag erfahren dann folge mir doch auf
					den
					sozialen Netzwerken.</p>
			</div>
		</div>
		<div id="g-ai2-2" class="g-Ebene_1 g-aiAbs"
			style="top:75.0926%;left:71.3385%;margin-left:-6.5885%;width:13.1771%;">
			<a routerLink='/contactform' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
				<p class="g-pstyle1">Termin anfragen</p>
			</a>
		</div>

		<div id="g-ai2-3" class="g-Ebene_1 g-aiAbs"
			style="top:96.5741%;left:50.9408%;margin-left:-25.2344%;width:50.4687%;">
			<p class="g-pstyle2">Meine Qualifikationen</p>
		</div>
	</div>

	<!-- Artboard: Qualifikationen -->
	<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Qualifikationen" class="g-artboard" style=""
		data-aspect-ratio="1.778" data-min-width="0">
		<div style="padding: 0 0 56.25% 0;"></div>
		<img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Qualifikationen-img" class="g-aiImg" alt=""
			src="assets/img/Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Qualifikationen.png" />
		<div id="g-ai3-1" class="g-Ebene_1 g-aiAbs"
			style="top:-3.5185%;left:50.9408%;margin-left:-25.2344%;width:50.4687%;">
			<p class="g-pstyle0">Meine Qualifikationen</p>
		</div>
		<a href="assets/pdf/Urkunde Ernährungsberaterin in Schwangerschaft.pdf" target="_blank">
			<div id="g-ai3-2" class="g-Ebene_1 g-aiAbs"
				style="top:50.8333%;left:73.8916%;margin-left:-7.1094%;width:14.2188%;">

				<p class="g-pstyle1">Schwangerschafts-</p>
				<p class="g-pstyle1">Beraterin</p>
			</div>
		</a>
		<div id="g-ai3-3" class="g-Ebene_1 g-aiAbs"
			style="top:51.0185%;left:27.4897%;margin-left:-7.2396%;width:14.4792%;">
			<a href="assets/pdf/Urkunde Diätassistent.pdf" target="_blank">
				<p class="g-pstyle1">Urkunde zur </p>
				<p class="g-pstyle1">Diätassistentin</p>
			</a>
		</div>
		<div id="g-ai3-4" class="g-Ebene_1 g-aiAbs"
			style="top:51.0185%;left:50.1833%;margin-left:-7.0573%;width:14.1146%;">
			<a href="assets/pdf/Fortbildungszertifikat.pdf" target="_blank">
				<p class="g-pstyle1">Verband deutscher Diätassistentinnen</p>
			</a>
		</div>
		<div id="g-ai3-5" class="g-Ebene_1 g-aiAbs"
			style="top:84.8148%;left:62.0423%;margin-left:-7.0573%;width:14.1146%;">
			<a href="assets/pdf/Urkunde Darmfachberaterin.pdf" target="_blank">
				<p class="g-pstyle1">Urkunde zur </p>
				<p class="g-pstyle1">Darmfachberaterin</p>
			</a>
		</div>
		<div id="S_uglings-_und_Kleinkindberatung" class="g-Ebene_1 g-aiAbs"
			style="top:84.9074%;left:37.7671%;margin-left:-7.0573%;width:14.1146%;">
			<a href="assets/pdf/Urkunde Ernährungsberaterin Säuglings und Kleinkindernährung.pdf" target="_blank">
				<p class="g-pstyle1">Säuglings- und </p>
				<p class="g-pstyle1">Kleinkindberatung</p>
			</a>
		</div>
		<div id="g-ai3-7" class="g-Ebene_1 g-aiAbs"
			style="top:96.8519%;left:52.1908%;margin-left:-25.2344%;width:50.4687%;">
			<p class="g-pstyle0">Beratungsablauf</p>
		</div>
	</div>

	<!-- Artboard: Beratungsablauf -->
	<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Beratungsablauf" class="g-artboard" style=""
		data-aspect-ratio="1.778" data-min-width="0">
		<div style="padding: 0 0 56.25% 0;"></div>
		<img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Beratungsablauf-img" class="g-aiImg" alt=""
			src="assets/img/Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Beratungsablauf.jpg" />
		<div id="g-ai4-1" class="g-Ebene_1 g-aiAbs"
			style="top:-3.2407%;left:52.1908%;margin-left:-25.2344%;width:50.4687%;">
			<p class="g-pstyle0">Beratungsablauf</p>
		</div>
		<div id="g-ai4-2" class="g-Ebene_1 g-aiAbs"
			style="top:27.8704%;left:42.373%;margin-left:-9.8177%;width:19.6354%;">
			<p class="g-pstyle1">Erstberatung und</p>
			<p class="g-pstyle1">Ernährungsan-amnese </p>
			<p class="display-none">&nbsp;</p>
			<p class="g-pstyle2 display-none"><span class="g-cstyle0">Vor der Erstberatung </span>erhältst du zuvor per
				E-Mail oder Post Erstunterlagen, sowie ein Ernährungstagebuch.</p>
			<p>&nbsp;</p>
			<p class="g-pstyle2"><span class="g-cstyle0">Während der Erstberatung </span></p>
			<p class="display-none480"> erfolgt eine ausführliche Ernährungsanamnese, welche gemeinsam mit deinem </p>
			<p class="display-none600"> Ernährngstagebuch die Grundlage für die Beratung darstellt.</p>
		</div>
		<div id="g-ai4-3" class="g-Ebene_1 g-aiAbs"
			style="top:27.8704%;left:65.7063%;margin-left:-9.8177%;width:19.6354%;">
			<p class="g-pstyle1">Auswertung und</p>
			<p class="g-pstyle1">Folgeberatung</p>
			<p>&nbsp;</p>
			<p class="g-pstyle2"><span class="g-cstyle0">Nach der Auswertung </span></p>
			<p class="display-none480">des Ernährung-stagebuches erarbeiten wir gemeinsam Strategien und
				Lösungsmög-lichkeiten</p>
			<p class="g-pstyle2 display-none600">zur dauerhaften Festigung sowie der gewünschten Zielerreichung.</p>
			<p>&nbsp;</p>
			<p class="g-pstyle3 display-none768 display-none600">Die Folgeberatungen <span class="g-cstyle1">finden je
					nach</span></p>
			<p class="g-pstyle2 display-none">deinen persönlichen Zielen statt.</p>
			<p>&nbsp;</p>
			<p class="g-pstyle3 display-none">In jeder Folgeberatung <span class="g-cstyle1">erarbeiten </span></p>
			<p class="g-pstyle2 display-none">wir gemeinsam neue Ziele und du lernst mehr über deine persönliche
				ausgewogene Ernährung. deine Erwartungen an die Beratung.</p>
		</div>
		<div id="g-ai4-4" class="g-Ebene_1 g-aiAbs"
			style="top:27.8704%;left:86.9563%;margin-left:-8.9323%;width:17.8646%;">
			<p class="g-pstyle1">Zielerreichung und</p>
			<p class="g-pstyle1">Abschlussges-präch</p>
			<p>&nbsp;</p>
			<p class="g-pstyle3"><span class="g-cstyle1">Nach deiner </span>Zielerreichung</p>
			<p class="display-none480"><span class="g-cstyle1"> erfolgt ein </span>Abschlussg-espräch.</p>
		</div>
		<div id="g-ai4-5" class="g-Ebene_1 g-aiAbs"
			style="top:27.963%;left:18.4183%;margin-left:-9.8177%;width:19.6354%;">
			<p class="g-pstyle1">Gegenseitiges </p>
			<p class="g-pstyle1">Kennenlernen</p>
			<p>&nbsp;</p>
			<p class="g-pstyle2"><span class="g-cstyle0">Erstanamnese</span></p>
			<p class="display-none480"> inkl. Beratungsumfang sowie Kostenanalyse folgen über ein <span
					class="g-cstyle0">kostenloses Gespräch. </span></p>
			<p>&nbsp;</p>
			<p class="g-pstyle2 display-none600">Hier mache ich mir ein Bild über </p>
			<p class="g-pstyle3 display-none"><span class="g-cstyle1">deine </span>aktuelle Ernährungssituation<span
					class="g-cstyle1">, </span>mögliche Herausforderungen <span class="g-cstyle1">und deine
				</span>Erwartungen an die Beratung<span class="g-cstyle1">.</span></p>
		</div>
		<div id="g-ai4-6" class="g-Ebene_1 g-aiAbs"
			style="top:90.6481%;left:74.6719%;margin-left:-6.5885%;width:13.1771%;">
			<a class="g-pstyle4" routerLink='/contactform' routerLinkActive="active"
				[routerLinkActiveOptions]="{exact: true}">Termin anfragen</a>
		</div>
		<div id="g-ai4-7" class="g-Ebene_1 g-aiAbs"
			style="top:96.0185%;left:49.2448%;margin-left:-42.9948%;width:85.9896%;">
			<p class="g-pstyle0">Krankenkassen-</p>
			<p class="g-pstyle0">bezuschussung</p>
		</div>
	</div>

	<!-- Artboard: Krankenkasse -->
	<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Krankenkasse" class="g-artboard" style=""
		data-aspect-ratio="1.778" data-min-width="0">
		<div style="padding: 0 0 56.25% 0;"></div>
		<img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Krankenkasse-img" class="g-aiImg" alt=""
			src="assets/img/Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Krankenkasse.png" />
		<div id="g-ai5-1" class="g-Ebene_1 g-aiAbs"
			style="top:-4.0741%;left:49.2448%;margin-left:-42.9948%;width:85.9896%;">
			<p class="g-pstyle0">Krankenkassen-</p>
			<p class="g-pstyle0">bezuschussung</p>
		</div>
		<div id="g-ai5-2" class="g-Ebene_1 g-aiAbs" style="top:31.0185%;left:19.8438%;width:64.7917%;">
			<p class="g-pstyle1 display-none768 display-none600">Grundsätzlich muss zwischen der Ernährungstherapie und
				der präventiven Ernährungsberatung unterschieden werden.</p>
			<p class="display-none768 display-none600">&nbsp;</p>
			<p class="display-none768 display-none600">&nbsp;</p>
			<p class="g-pstyle2">Ernährungsberatung nach &sect; 43 SGB V - Ernährungstherapie</p>
			<p>&nbsp;</p>
			<p>Diese Beratungsleistung richtet sich an Personen mit ernährungsbedingten Erkrankungen und wird nur in
				Verbindung mit einer ärztlichen Notwendigkeitsbescheinigung durchgeführt. Diese kann dir dein Arzt
				ausstellen (Für den Arzt ist die Ausstellung budgetneutral). Diese kannst du vor Inanspruchnahme der
				Ernährungsberatung deiner Krankenkasse vorlegen.</p>
			<p class="display-none480">Die meisten Krankenkassen beteiligen sich gegen Vorlage der bereits beglichenen
				Rechnung anteilig an den Kosten für bis zu 5 Beratungstermine.</p>
			<p class="display-none480">Bitte informiere dich hierzu nochmals genauer vorab bei deiner Krankenkassen über
				die Höhe eines Zuschusses.</p>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
			<p class="g-pstyle2 display-none">Ernährungsberatung nach &sect; 20 SGB V &ndash; Prävention</p>
			<p class="display-none">&nbsp;</p>
			<p class="display-none">Diese allgemeine Ernährungsberatung richtet sich an gesunde Menschen.</p>
			<p class="display-none">Vor Beratungsbeginn kannst du nach einer anteiligen Kostenerstattung bei deiner
				Krankenkasse anfragen.</p>
		</div>
		<div id="g-ai5-3" class="g-Ebene_1 g-aiAbs"
			style="top:97.5926%;left:50.9736%;margin-left:-25.7031%;width:51.4063%;">
			<p class="g-pstyle0">Was ich für dich tun kann</p>
		</div>
	</div>

	<!-- Artboard: Leistungen -->
	<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Leistungen" class="g-artboard" style=""
		data-aspect-ratio="1.778" data-min-width="0">
		<div style="padding: 0 0 56.25% 0;"></div>
		<img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Leistungen-img" class="g-aiImg" alt=""
			src="assets/img/Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Leistungen.jpg" />
		<div id="g-ai6-1" class="g-Ebene_1 g-aiAbs"
			style="top:-2.5%;left:50.9736%;margin-left:-25.7031%;width:51.4063%;">
			<p class="g-pstyle0">Was ich für dich</p>
			<p class="g-pstyle0"> tun kann</p>
		</div>
		<div id="g-ai6-2" class="g-Ebene_1 g-aiAbs"
			style="top:50.1852%;left:52.0584%;margin-left:-7.0573%;width:14.1146%;">
			<a routerLink='/service-two'>
				<div id="g-ai6-6" class="g-Ebene_1 g-aiAbs" style="bottom: 196%;
				left: 83.3548%;
				margin-left: -88.5%;
				width: 128%;
				height: 681%;"></div>
			</a>
			<a routerLink="/service-two">
				<p class="g-pstyle1">Säuglinge</p>
			</a>
		</div>
		<div id="g-ai6-3" class="g-Ebene_1 g-aiAbs"
			style="top:50.1852%;left:26.8068%;margin-left:-7.5521%;width:15.1042%;">
			<a routerLink="/service-one">
				<div id="g-ai6-6" class="g-Ebene_1 g-aiAbs" style="bottom: 196%;
				left: 83.3548%;
				margin-left: -88.5%;
				width: 128%;
				height: 681%;"></div>
			</a>
			<a routerLink="/service-one">
				<p class="g-pstyle1">Schwangerschaft & </p>
				<p class="g-pstyle1">Stillzeit</p>
			</a>
		</div>
		<div id="g-ai6-4" class="g-Ebene_1 g-aiAbs"
			style="top:50.2778%;left:78.2871%;margin-left:-7.1094%;width:14.2188%;">
			<a routerLink="/service-three">
				<div id="g-ai6-6" class="g-Ebene_1 g-aiAbs" style="bottom: 196%;
				left: 83.3548%;
				margin-left: -88.5%;
				width: 128%;
				height: 681%;"></div>
			</a>
			<a routerLink="/service-three">
				<p class="g-pstyle1">Kleinkinder</p>
			</a>
		</div>
		<div id="g-ai6-5" class="g-Ebene_1 g-aiAbs"
			style="top:83.7037%;left:79.0308%;margin-left:-7.0573%;width:14.1146%;">
			<a routerLink="/service-six">
				<div id="g-ai6-6" class="g-Ebene_1 g-aiAbs" style="top: -398.2963%;
				left: 53.3548%;
				margin-left: -58.5%;
				width: 111%;
				height: 364%;">
				</div>
			</a>
			<a routerLink="/service-six">
				<p class="g-pstyle1">Vorträge </p>
				<p class="g-pstyle1">buchen</p>
			</a>
		</div>
		<div id="g-ai6-6" class="g-Ebene_1 g-aiAbs" style="top:83.7037%;left:53.3548%;margin-left:-7.5%;width:15%;">
			<a routerLink="/service-four">
				<div id="g-ai6-6" class="g-Ebene_1 g-aiAbs" style="top: -196.2963%;
			left: 53.3548%;
			margin-left: -58.5%;
			width: 100%;
			height: 186%;">
				</div>
			</a>

			<a routerLink="/service-four">
				<p class="g-pstyle1">Kühlschrank-check</p>
				<p class="g-pstyle1 display-none480">& Einkaufscoaching</p>
			</a>
		</div>
		<div id="g-ai6-7" class="g-Ebene_1 g-aiAbs"
			style="top:83.7963%;left:25.917%;margin-left:-7.0573%;width:14.1146%;">
			<a routerLink="/service-five">
				<div id="g-ai6-6" class="g-Ebene_1 g-aiAbs" style="    top: -390.2963%;
				left: 53.3548%;
				margin-left: -88.5%;
				width: 103%;
				height: 354%;">
				</div>
			</a>
			<a routerLink="/service-five">
				<p class="g-pstyle1">Kurse </p>
				<p class="g-pstyle1">buchen</p>
			</a>
		</div>

	</div>

	<!-- Artboard: Honorar -->
	<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Honorar" class="g-artboard" style=""
		data-aspect-ratio="1.778" data-min-width="0">
		<div style="padding: 0 0 56.25% 0;"></div>
		<img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Honorar-img" class="g-aiImg" alt=""
			src="assets/img/Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Honorar.jpg" />
		<div id="g-ai13-1" class="g-Ebene_1 g-aiAbs"
			style="top:-3.0556%;left:50.0025%;margin-left:-25.2344%;width:50.4687%;">
			<p class="g-pstyle0">Honorar</p>
		</div>
		<div id="g-ai13-2" class="g-Ebene_1 g-aiAbs"
			style="top:32.037%;left:42.0108%;margin-left:-9.8177%;width:19.6354%;">
			<p class="g-pstyle1">Einzelberatung</p>
			<p class="g-pstyle2">Folgeberatung</p>
			<p>&nbsp;</p>
			<p>Individuelle Einzelberatung als Folgeberatung inkl.</p>
			<p class="display-none600">Vor-und Nach-</p>
			<p class="display-none600">bereitung</p>
			<p class="display-none">&nbsp;</p>
			<p class="display-none">Beratungshonorar: 87€ für 45 min</p>
		</div>
		<div id="g-ai13-3" class="g-Ebene_1 g-aiAbs"
			style="top:32.037%;left:65.3441%;margin-left:-9.8177%;width:19.6354%;">
			<p class="g-pstyle1">Einzelberatung</p>
			<p class="g-pstyle2">Kurzberatung</p>
			<p>&nbsp;</p>
			<p>Individuelle Einzelberatung für Fragen</p>
			<p class="display-none600"> außerhalb der Beratung</p>
			<p>&nbsp;</p>
			<p class="display-none">Beratungsdauer: ab 15 Minuten</p>
			<p class="display-none">Beratungshonorar: 29€ pro 15 min</p>
		</div>
		<div id="g-ai13-4" class="g-Ebene_1 g-aiAbs"
			style="top:32.037%;left:87.1149%;margin-left:-9.4531%;width:18.9063%;">
			<p class="g-pstyle1">Aktuelle Kurse</p>
			<p class="g-pstyle2">Beikostkurs</p>
			<p>&nbsp;</p>
			<p>Beikostworkshop </p>
			<p>Learning by Doing- Gesunde, ausgewogene Beikosteinführung mit deinem Baby </p>
			<p>&nbsp;</p>
			<p class="display-none">3 Kurseinheiten à 120 Minuten</p>
			<p class="display-none">Beratungshonorar: 98€</p>
		</div>
		<div id="g-ai13-5" class="g-Ebene_1 g-aiAbs"
			style="top:32.037%;left:18.0561%;margin-left:-9.8177%;width:19.6354%;">
			<p class="g-pstyle1">Einzelberatung</p>
			<p class="g-pstyle2">Erstberatung</p>
			<p>&nbsp;</p>
			<p>Individuelle Einzelberatung inkl.</p>
			<p class="display-none600"> Anamnese, Auswertung Ernährungsprotokoll</p>
			<p class="display-none">&nbsp;</p>
			<p class="display-none"></p>
			<p class="display-none">Beratungshonorar: 119€ für 60 min</p>
		</div>
		<div id="g-ai13-6" class="g-Ebene_1 g-aiAbs"
			style="top:68.4259%;left:65.4226%;margin-left:-9.8177%;width:19.6354%;">
			<p class="g-pstyle1">Vorträge buchen</p>
			<p>&nbsp;</p>
			<p class="display-none">Vortragshonorar: 60 min ab 178€</p>
		</div>
		<div id="g-ai13-7" class="g-Ebene_1 g-aiAbs"
			style="top:69.537%;left:17.3185%;margin-left:-9.8177%;width:19.6354%;">
			<p class="g-pstyle1">Kühlschrankcheck</p>
			<p>&nbsp;</p>
			<p>Begutachtung des Kühlschranks und der Vorratsschränke </p>
			<p class="display-none">&nbsp;</p>
			<p class="display-none">Beratungshonorar: 29€ pro 15 min</p>
		</div>
		<div id="g-ai13-8" class="g-Ebene_1 g-aiAbs"
			style="top:69.537%;left:40.9123%;margin-left:-9.4531%;width:18.9063%;">
			<p class="g-pstyle1">Einkaufscoaching</p>
			<p>&nbsp;</p>
			<p>Supermarkt Begleitung</p>
			<p>&nbsp;</p>
			<p class="display-none600">Beratungshonorar: 99€ für 60 min</p>
		</div>
		<div id="g-ai13-9" class="g-Ebene_1 g-aiAbs"
			style="top:89.4444%;left:90.6094%;margin-left:-6.5885%;width:13.1771%;">
			<a class="g-pstyle3" routerLink='/contactform' routerLinkActive="active"
				[routerLinkActiveOptions]="{exact: true}">Termin anfragen</a>
		</div>
	</div>

	<!-- Artboard: Ende -->
	<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Ende" class="g-artboard" style=""
		data-aspect-ratio="1.778" data-min-width="0">
		<div style="padding: 0 0 56.25% 0;"></div>
		<img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Ende-img" class="g-aiImg" alt=""
			src="assets/img/Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Ende.jpg" />
		<div id="g-ai14-1" class="g-Ebene_1 g-aiAbs"
			style="top:38.7037%;left:62.1379%;margin-left:-25.2344%;width:50.4687%;">
			<p class="g-pstyle0">Kontaktinformationen</p>
		</div>
		<div id="g-ai14-2" class="g-Ebene_1 g-aiAbs g-aiPointText"
			style="top:63.0838%;margin-top:-19.3px;left:87.9646%;margin-left:-115.5px;width:231px;">
			<p class="g-pstyle1">Telefonnummer</p>
		</div>
		<div id="g-ai14-3" class="g-Ebene_1 g-aiAbs"
			style="top:61.9444%;left:69.2689%;margin-left:-7.2396%;width:14.4792%;">
			<p class="g-pstyle2">E-Mail Adresse</p>
		</div>
		<div id="g-ai14-4" class="g-Ebene_1 g-aiAbs"
			style="top:61.9444%;left:49.0684%;margin-left:-7.2396%;width:14.4792%;">
			<p class="g-pstyle2">Postanschrift</p>
		</div>
		<div id="g-ai14-5" class="g-Ebene_1 g-aiAbs"
			style="top:67.8704%;left:49.0606%;margin-left:-7.2396%;width:14.4792%;">
			<p class="g-pstyle3">Katharina Richter</p>
			<p class="g-pstyle3">85221 Dachau</p>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
		</div>
		<div id="g-ai14-6" class="g-Ebene_1 g-aiAbs"
			style="top:70.9259%;left:69.8939%;margin-left:-10.1042%;width:20.2083%;">
			<p class="g-pstyle3">Kontakt@Richter-Ernährungsberatung.de</p>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
		</div>
		<div id="g-ai14-7" class="g-Ebene_1 g-aiAbs"
			style="top:71.3889%;left:87.9688%;margin-left:-6.0417%;width:12.0833%;">
			<p class="g-pstyle3">+49 174 8209687</p>
		</div>
		<div id="g-ai14-8" class="g-Ebene_1 g-aiAbs"
			style="top:79.2593%;left:49.0625%;margin-left:-5.1042%;width:10.2083%;">
			<a routerLink="/impressum">
				<p class="g-pstyle2 ">Impressum</p>
			</a>
		</div>
		<div id="g-ai14-9" class="g-Ebene_1 g-aiAbs"
			style="top:79.2593%;left:68.2031%;margin-left:-5.5469%;width:11.0938%;">
			<a routerLink="/datenschutz">
				<p class="g-pstyle2">Datenschutz</p>
			</a>
		</div>
		<div id="g-ai14-10" class="g-Ebene_1 g-aiAbs"
			style="top:87.037%;left:89.5677%;margin-left:-6.5885%;width:13.1771%;">
			<a routerLink='/contactform' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
				<p class="g-pstyle4">Termin anfragen</p>
			</a>

		</div>
	</div>

</div>

<!-- End ai2html - 2021-01-23 00:46 -->