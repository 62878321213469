<app-header1></app-header1>
<!--====== Banner part start ======-->

<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-box" class="ai2html">



	<!-- Artboard: _ber_mich_2 -->
	<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Krankenkasse" class="g-artboard" style=""
		data-aspect-ratio="1.778" data-min-width="0">
		<div style="padding: 0 0 56.25% 0;"></div>
		<img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Krankenkasse-img" class="g-aiImg" alt=""
			src="assets/img/Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Krankenkasse.png" />
		<div id="g-ai5-1" class="g-Ebene_1 g-aiAbs"
			style="top:-4.0741%;left:49.2448%;margin-left:-42.9948%;width:85.9896%;">
			<p class="g-pstyle0"></p>
			<p class="g-pstyle0">Datenschutzerklärung</p>
		</div>
		<div id="g-ai5-2" class="g-Ebene_1 g-aiAbs" style="top:31.0185%;left:19.8438%;width:64.7917%;">
			<p>Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO),
				ist:</p>
			<p>Katharina Richter</p>
			<h2>Ihre Betroffenenrechte</h2>
			<p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte
				ausüben:</p>
			<ul>
				<li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
				<li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
				<li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
				<li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch
					nicht löschen dürfen (Art. 18 DSGVO),</li>
				<li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
				<li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit
					uns abgeschlossen haben (Art. 20 DSGVO).</li>
			</ul>
			<p>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft
				widerrufen.</p>
			<p>Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige
				Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle
				zuständige Behörde.</p>
			<p>Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter: <a
					href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
					target="_blank"
					rel="nofollow noopener">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.
			</p>
			<p></p>

		</div>

	</div>
	<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Krankenkasse" class="g-artboard" style=""
		data-aspect-ratio="1.778" data-min-width="0">
		<div style="padding: 0 0 56.25% 0;"></div>
		<img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Krankenkasse-img" class="g-aiImg" alt=""
			src="assets/img/impressum.jpg" />

		<div id="g-ai5-2" class="g-Ebene_1 g-aiAbs" style="top:4.0185%;left:19.8438%;width:64.7917%;">
			<h2>Erbringung kostenpflichtiger Leistungen</h2>
			<h3>Art und Zweck der Verarbeitung:</h3>
			<p>Zur Erbringung kostenpflichtiger Leistungen werden von uns zusätzliche Daten erfragt, wie z.B.
				Zahlungsangaben, um Ihre Bestellung ausführen zu können.</p>
			<h3>Rechtsgrundlage:</h3>
			<p>Die Verarbeitung der Daten, die für den Abschluss des Vertrages erforderlich ist, basiert auf Art. 6 Abs.
				1 lit. b DSGVO.</p>
			<h3>Empfänger:</h3>
			<p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
			<p></p>
			<h3>Speicherdauer:</h3>
			<p>Wir speichern diese Daten in unseren Systemen bis die gesetzlichen Aufbewahrungsfristen abgelaufen sind.
				Diese betragen grundsätzlich 6 oder 10 Jahre aus Gründen der ordnungsmäßigen Buchführung und
				steuerrechtlichen Anforderungen.</p>
			<h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
			<p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Ohne die Bereitstellung Ihrer
				personenbezogenen Daten können wir Ihnen keinen Zugang auf unsere angebotenen Inhalte und Leistungen
				gewähren.</p>
			<p></p>
			<h2>Kontaktformular</h2>
			<h3>Art und Zweck der Verarbeitung:</h3>
			<p>Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen Kommunikation mit Ihnen gespeichert.
				Hierfür ist die Angabe einer validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient der
				Zuordnung der Anfrage und der anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist
				optional.</p>



		</div>

	</div>
	<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Krankenkasse" class="g-artboard" style=""
		data-aspect-ratio="1.778" data-min-width="0">
		<div style="padding: 0 0 56.25% 0;"></div>
		<img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Krankenkasse-img" class="g-aiImg" alt=""
			src="assets/img/impressum2.png" />

		<div id="g-ai5-2" class="g-Ebene_1 g-aiAbs" style="top:4.0185%;left:19.8438%;width:64.7917%;">

			<h3>Rechtsgrundlage:</h3>
			<p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf der Grundlage eines
				berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO).</p>
			<p>Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine unkomplizierte Kontaktaufnahme
				ermöglichen. Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche
				Anschlussfragen gespeichert.</p>
			<p>Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die Verarbeitung der in das
				Kontaktformular eingegebenen Daten zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b
				DSGVO).</p>
			<h3>Empfänger:</h3>
			<p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
			<p></p>
			<h3>Speicherdauer:</h3>
			<p>Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage gelöscht.</p>
			<p>Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den gesetzlichen Aufbewahrungsfristen nach
				HGB und löschen Ihre Daten nach Ablauf dieser Fristen. </p>
			<h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
			<p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir können Ihre Anfrage jedoch nur
				bearbeiten, sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.</p>
			<p></p>

		</div>

	</div>
	<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Krankenkasse" class="g-artboard" style=""
		data-aspect-ratio="1.778" data-min-width="0">
		<div style="padding: 0 0 56.25% 0;"></div>
		<img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Krankenkasse-img" class="g-aiImg" alt=""
			src="assets/img/impressum.jpg" />

		<div id="g-ai5-2" class="g-Ebene_1 g-aiAbs" style="top:4.0185%;left:19.8438%;width:64.7917%;">

			<h3>Widerruf der Einwilligung:</h3>
			<p>Sie können das Tracking durch Google Analytics auf unserer Website unterbinden, indem Sie <a
					title="Google Analytics Opt-Out-Cookie setzen"
					onClick="gaOptout();alert('Google Analytics wurde deaktiviert');" href="#">diesen Link
					anklicken</a>. Dabei wird ein Opt-out-Cookie auf Ihrem Gerät installiert. Damit wird die Erfassung
				durch Google Analytics für diese Website und für diesen Browser zukünftig verhindert, solange das Cookie
				in Ihrem Browser installiert bleibt.</p>
			<p>Sie können darüber hinaus die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
				Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls
				nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. </p>
			<p>Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite
				bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google
				verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und
				installieren: <a href="http://tools.google.com/dlpage/gaoptout?hl=de" rel="noopener"
					target="_blank">Browser Add On zur Deaktivierung von Google Analytics</a>.</p>
			<p></p>
			<hr>
			<h2>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h2>
			<h3>Einzelfallbezogenes Widerspruchsrecht</h3>
			<p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die
				Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO
				(Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies
				gilt auch für ein auf diese Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.</p>
			<p>Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn,
				wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte
				und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung
				von Rechtsansprüchen.</p>

			<h3>Empfänger eines Widerspruchs</h3>
			<p></p>
			<hr>
		</div>

	</div>
	<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Krankenkasse" class="g-artboard" style=""
		data-aspect-ratio="1.778" data-min-width="0">
		<div style="padding: 0 0 56.25% 0;"></div>
		<img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Krankenkasse-img" class="g-aiImg" alt=""
			src="assets/img/impressum2.png" />

		<div id="g-ai5-2" class="g-Ebene_1 g-aiAbs" style="top:4.0185%;left:19.8438%;width:64.7917%;">
			<h2>Änderung unserer Datenschutzbestimmungen</h2>
			<p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen
				Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen,
				z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue
				Datenschutzerklärung.</p>

			<h2>Fragen an den Datenschutzbeauftragten</h2>
			<p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an
				die für den Datenschutz verantwortliche Person in unserer Organisation:</p>
			<p></p>
			<p><em>Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den Experten für <a
						href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/" target="_blank"
						rel="noopener">externe Datenschutzbeauftragte</a> (Version #2020-09-30).</em></p>


		</div>

	</div>
</div>