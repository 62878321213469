<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Ende" class="g-artboard" style=""
	data-aspect-ratio="1.778" data-min-width="0">
	<div style="padding: 0 0 56.25% 0;"></div>
	<img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Ende-img" class="g-aiImg" alt=""
		src="assets/img/Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Ende.jpg" />
	<div id="g-ai14-1" class="g-Ebene_1 g-aiAbs"
		style="top:38.7037%;left:62.1379%;margin-left:-25.2344%;width:50.4687%;">
		<p class="g-pstyle0">Kontaktinformationen</p>
	</div>
	<div id="g-ai14-2" class="g-Ebene_1 g-aiAbs g-aiPointText"
		style="top:63.0838%;margin-top:-19.3px;left:87.9646%;margin-left:-115.5px;width:231px;">
		<p class="g-pstyle1">Telefonnummer</p>
	</div>
	<div id="g-ai14-3" class="g-Ebene_1 g-aiAbs"
		style="top:61.9444%;left:69.2689%;margin-left:-7.2396%;width:14.4792%;">
		<p class="g-pstyle2">E-Mail Adresse</p>
	</div>
	<div id="g-ai14-4" class="g-Ebene_1 g-aiAbs"
		style="top:61.9444%;left:49.0684%;margin-left:-7.2396%;width:14.4792%;">
		<p class="g-pstyle2">Postanschrift</p>
	</div>
	<div id="g-ai14-5" class="g-Ebene_1 g-aiAbs"
		style="top:67.8704%;left:49.0606%;margin-left:-7.2396%;width:14.4792%;">
		<p class="g-pstyle3">Katharina Richter</p>
		<p class="g-pstyle3">85221 Dachau</p>
		<p>&nbsp;</p>
		<p>&nbsp;</p>
		<p>&nbsp;</p>
		<p class="g-pstyle2 custom-m600 display-none480">0</p>
	</div>
	<div id="g-ai14-6" class="g-Ebene_1 g-aiAbs"
		style="top:70.9259%;left:69.8939%;margin-left:-10.1042%;width:20.2083%;">
		<p class="g-pstyle3">Kontakt@Richter-Ernährungsberatung.de</p>
		<p>&nbsp;</p>
		<p>&nbsp;</p>
		<p class="g-pstyle2 display-none480"> <br> 0</p>
	</div>
	<div id="g-ai14-7" class="g-Ebene_1 g-aiAbs"
		style="top:71.3889%;left:87.9688%;margin-left:-6.0417%;width:12.0833%;">
		<p class="g-pstyle3">+49 174 8209687</p>
	</div>
	<div id="g-ai14-8" class="g-Ebene_1 g-aiAbs"
		style="top:79.2593%;left:49.0625%;margin-left:-5.1042%;width:10.2083%;">
		<a routerLink='/blog-detail'>

			<p class="g-pstyle2 display-none480">Impressum</p>
		</a>
	</div>
	<div id="g-ai14-9" class="g-Ebene_1 g-aiAbs"
		style="top:79.2593%;left:68.2031%;margin-left:-5.5469%;width:11.0938%;">
		<a routerLink='/blog-detail'>
			<p class="g-pstyle2 display-none480">Datenschutz</p>
		</a>
	</div>
	<div id="g-ai14-10" class="g-Ebene_1 g-aiAbs"
		style="top:87.037%;left:88.5677%;margin-left:-6.5885%;width:13.1771%;">
		<p class="g-pstyle4">Termin anfragen</p>
	</div>
</div>