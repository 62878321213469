
<app-header1></app-header1>
<!--====== Banner part start ======-->

<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-box" class="ai2html">
	<!-- Artboard: Vortr_ge -->
	<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Vortr_ge" class="g-artboard" style=""
		data-aspect-ratio="1.778" data-min-width="0">
		<div style="padding: 0 0 56.25% 0;"></div>
		<img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Vortr_ge-img" class="g-aiImg" alt=""
			src="assets/img/Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Vortr_ge.jpg" />
		<div id="g-ai12-1" class="g-Ebene_1 g-aiAbs"
			style="top:-1.9444%;left:48.7747%;margin-left:-25.2344%;width:50.4687%;">
			<p class="g-pstyle0">Vorträge buchen</p>
		</div>
		<div id="g-ai12-2" class="g-Ebene_1 g-aiAbs" style="top:28.9815%;left:56.3124%;width:28.75%;">
			<p class="g-pstyle1">Elternschulungen</p>
			<p class="g-pstyle1">(Vorträge für Elternabende)</p>
			<p class="display-none480">&nbsp;</p>
			<p class="g-pstyle3 display-none480">Mögliche Vortragssthemen:</p>
			<p>- Ernährungsgrundlagen</p>
			<p>- Ernährungserziehung</p>
			<p>- Umgang mit Allergien</p>
			<p class="g-pstyle3"><span class="g-cstyle0">-</span> ... vieles mehr</p>
		</div>
		<div id="g-ai12-3" class="g-Ebene_1 g-aiAbs" style="top:28.9815%;left:22.5986%;width:28.75%;">
			<p class="g-pstyle1">Mitarbeiterschulungen </p>
			<p class="g-pstyle1">(Krippen, Kitas, Schulen)</p>
			<p class="g-pstyle3 display-none480">Mögliche Vortragssthemen:</p>
			<p class="display-none768 display-none600">- Ernährungsgrundlagen</p>
			<p class="">- Ernährungserziehung</p>
			<p class="">- Umgang mit Allergien</p>
			<p class="g-pstyle3"><span class="g-cstyle0">-</span> .... vieles mehr</p>
		</div>
		<div id="g-ai12-4" class="g-Ebene_1 g-aiAbs g-aiPointText"
			style="top:62.9207%;margin-top:-50.5px;left:29.886%;width:306px;">
			<p class="g-pstyle4">Schulungen:</p>
			<p class="g-pstyle4">Schulungsdauer: 60 Minuten</p>
			<p class="g-pstyle4">Beratungshonorar: nur ab 178&euro;</p>
		</div>
		<div id="g-ai12-5" class="g-Ebene_1 g-aiAbs"
			style="top:78.9815%;left:73.9948%;margin-left:-6.5885%;width:13.1771%;">
			<a class="g-pstyle5" routerLink='/contactform' routerLinkActive="active"
				[routerLinkActiveOptions]="{exact: true}">Termin anfragen</a>
		</div>
		<div id="g-ai12-6" class="g-Ebene_1 g-aiAbs"
			style="top:97.037%;left:50.0025%;margin-left:-25.2344%;width:50.4687%;">
			<p class="g-pstyle0">Honorar</p>
		</div>
	</div>
</div>