<app-header1></app-header1>
<!--====== Banner part start ======-->

<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-box" class="ai2html">

	<!-- Artboard: Kurse -->
	<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Kurse" class="g-artboard" style=""
		data-aspect-ratio="1.778" data-min-width="0">
		<div style="padding: 0 0 56.25% 0;"></div>
		<img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Kurse-img" class="g-aiImg" alt=""
			src="assets/img/Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Kurse.jpg" />
		<div id="g-ai10-1" class="g-Ebene_1 g-aiAbs"
			style="top:-2.3148%;left:50%;margin-left:-25.2344%;width:50.4687%;">
			<p class="g-pstyle0">Learning by Doing- Gesunde, ausgewogene Beikosteinführung mit deinem Baby</p>
		</div>
		<div id="g-ai10-2" class="g-Ebene_1 g-aiAbs" style="top:42.648%;left:22.0311%;width:63.9063%;">
			<p class="g-pstyle1 display-none">Der Beikostworkshop</p>
			<p class="display-none">In dem Beikostworkshop- Learning by Doing- Gesunde, ausgewogene Beikosteinführung
				mit deinem Baby -, unterstütze ich dich bei Fragen rund um die Themen Beikosteinführung, sowie dem
				Umstieg zur Familienkost.
				Der Workshop umfasst 3 Kurseinheiten mit jeweils 120 Minuten.
				Du erhältst unter anderem Rezeptideen, Handouts für zuhause, Rezepte, Strategien wie dein Kind ein
				positives Essverhalten entwickeln kann und somit Spaß am Essen und Entdecken von neuen Lebensmitteln
				hat.
				Es besteht die Möglichkeit, dass der Workshop von den Krankenkassen bezuschusst wird, melde dich
				diesbezüglich einfach bei mir. </p>
			<p>&nbsp;</p>
			<p class="g-pstyle2">Workshop-Themen: </p>
			<p>&nbsp;</p>
			<p class="">- Beikosteinführung (Wann, was, wie?)</p>
			<p class="">- Brei, Fingerfood oder beides? Selbstgemacht oder lieber gekauft?</p>
			<p class="display-none768 display-none600">- Allergieprävention</p>
			<p class="display-none768 display-none600">- Lebensmittelauswahl </p>
			<p class="display-none768 display-none600">- Sind Kinderlebensmittel sinnvoll? </p>
			<p class="display-none768 display-none600">- Wie gelingt der Umstieg zur Familienkost?</p>
			<p class="display-none768 display-none600">- Wie schaffe ich es, dass mein Kind eine Vielfalt an
				Lebensmitteln akzeptiert? </p>
			<p class="display-none768 display-none600">- Wie vermittle ich meinem Kind ein positives Essverhalten? </p>

			<p class="g-pstyle2"><span class="g-cstyle0">- </span>...vieles mehr </p>
		</div>
		<div id="g-ai10-3" class="g-Ebene_1 g-aiAbs g-aiPointText" style="top: 97.328%;
			margin-top: -50.5px;
			left: 63.885%;
			width: 403px;">
			<p class="g-pstyle3">Beikostworkshop: 3 Sitzungen à 120 Minuten</p>
			<p class="g-pstyle3">Beratungshonorar: 89&euro;</p>
		</div>
		<div id="g-ai10-4" class="g-Ebene_1 g-aiAbs"
			style="top:77.037%;left:72.7969%;margin-left:-6.5885%;width:13.1771%;">
			<a class="g-pstyle4" routerLink='/contactform' routerLinkActive="active"
				[routerLinkActiveOptions]="{exact: true}">Termin anfragen</a>
		</div>
		<div id="g-ai10-5" class="g-Ebene_1 g-aiAbs"
			style="top:98.0556%;left:50.5253%;margin-left:-33.8542%;width:67.7083%;">
			<p class="g-pstyle0">Kühlschrankcheck & </p>
			<p class="g-pstyle0">Einkaufscoaching</p>
		</div>
	</div>
</div>