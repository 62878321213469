<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->
<section class="breadcrumb-section bg-img-c" style="background-image: url(assets/img/banner/01.jpg);">
  <div class="container">
    <div class="breadcrumb-text">
      <h1 class="page-title">Kurse</h1>
    </div>
  </div>
  <div class="breadcrumb-shapes">
    <div class="one"></div>
    <div class="two"></div>
    <div class="three"></div>
  </div>
</section>
<!--====== Breadcrumb part End ======-->

<!--====== About Section start ======-->
<section class="about-section about-illustration-img section-gap">
  <div class="container">
    <div class="row no-gutters justify-content-lg-end justify-content-center">
      <div class="col-lg-10 col-md-10">
        <div class="about-text">
          <div class="section-title left-border mb-40">
            <span class="title-tag">Kurse</span>
            <h2 class="title">Beikostkurs </h2>
          </div>
          <p class="mb-25">
            B(r)eikost- Essen will gelernt sein
            In dem zertifizierten Beikostkurs- B(r)eikost- Essen will gelernt sein-, unterstütze ich dich bei Fragen
            rund um die Themen der Beikosteinführung, sowie dem Umstieg
            zur Familienkost.
            Unter Anderem werden Fragen geklärt wie:
          </p>
          <ul class="about-list">
            <li> <i class="far fa-check"></i> Brei, Fingerfood oder beides?</li>
            <li> <i class="far fa-check"></i> selbstgekocht oder Gläschen?</li>
            <li> <i class="far fa-check"></i> Was sind geeignete babyfreundliche Familienrezepte</li>
            <li> <i class="far fa-check"></i> Allergieprävention</li>
            <li> <i class="far fa-check"></i> Machen Kinderlebensmittel Sinn?</li>

          </ul>
          <a routerLink='/about-us' class="main-btn">Termin anfragen</a>
        </div>
      </div>
    </div>
  </div>
</section>
<!--====== About Section end ======-->

<!--====== Client Area End ======-->
<app-footer1></app-footer1>
