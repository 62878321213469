<app-header1></app-header1>
<!-- Artboard: Titel -->
<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-box" class="ai2html">

  <div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Krankenkasse" class="g-artboard"
    style="max-width: 1920px;max-height: 1080px; padding: 54px;" data-aspect-ratio="1.778" data-min-width="0">
    <h2 class="text-center mb-5 title">Termin Anfrage</h2>
    <ng-container *ngIf="showSuccess">
      <p class="g-pstyle0">Anfrage versendet!</p>
    </ng-container>

    <ng-container *ngIf="!showSuccess">

      <div class="row">
        <div class="col-md-6 offset-md-3">
          <form [formGroup]="formData" (ngSubmit)="onSubmit(formData.value)">
            <div class="form-group">
              <mat-form-field>
                <input type="email" class="form-control" matInput name="Email" placeholder="Email-Adresse"
                  formControlName="Email">
              </mat-form-field>
              <div class="text-danger" *ngIf="formData.invalid && formData.errors?.required">
                <small>Das Feld ist erforderlich!</small>
              </div>
            </div>
            <div class="form-group">
              <mat-form-field>
                <input type="number" class="form-control" matInput name="value" placeholder="Telefonnummer"
                  formControlName="phone">
              </mat-form-field>
            </div>
            <div class="form-group paragraph">
              <mat-form-field>
                <input type="paragraph" class="form-control" matInput name="Fullname" placeholder="Vor- und Nachname"
                  formControlName="Fullname">
              </mat-form-field>
            </div>
            <div class="form-group paragraph">
              <label for="comment">Nachricht</label>
              <mat-form-field floatLabel="never" class="display-block">
                <textarea class="form-control" formControlName="Comment" name="Comment" rows="3" matInput placeholder=""
                  disabled></textarea>
              </mat-form-field>
            </div>
            <div class="form-group paragraph">
              <mat-form-field>
                <mat-chip-list #chipList aria-label="Wähle deine Wunschtermine" (click)="picker.open()">
                  <mat-chip *ngFor="let value of model" [selectable]="false" [removable]="true"
                    (removed)="remove(value)">
                    {{ value | date }}
                    <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                  </mat-chip>
                  <input [value]="resetModel" matInput [matDatepickerFilter]="weekendsDatesFilter"
                    [matDatepicker]="picker" placeholder="Wähle deine Wunschtermine" (dateChange)="dateChanged($event)"
                    [matChipInputFor]="chipList" hidden />
                </mat-chip-list>
                <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker [startAt]="init" [dateClass]="dateClass"></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="form-group paragraph">
              <mat-form-field appearance="fill">
                <mat-label>Wähle dein Beratungsthema</mat-label>
                <mat-select formControlName="kurs">
                  <mat-option *ngFor="let kurs of kurse" [value]="kurs.value">
                    {{kurs.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <button type="submit" class="main-btn main-btn-3">Absenden</button>
          </form>
        </div>
      </div>
    </ng-container>
  </div>