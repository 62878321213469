<header class="sticky-header">
  <!-- Header Menu  -->
  <div class="header-nav">
    <div class="container-fluid container-1600">
      <div class="nav-container" style="height: 70px;">
        <!-- Site Logo -->
        <div class="site-logo" style="width: 74px;">
          <a routerLink='/'><img src="assets/img/logo.png" alt="Logo"></a>
        </div>

        <!-- Main Menu -->
        <div class="nav-menu d-lg-flex align-items-center">

          <!-- Navbar Close Icon -->
          <div class="navbar-close">
            <div class="cross-wrap"><span></span><span></span></div>
          </div>

          <!-- Mneu Items -->
          <div class="menu-items">
            <ul>
              <li>
                <a routerLink='/' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Startseite</a>
              </li>
              <li class="has-submemu">
                <a href="javascript:void(0)">Aktuelle Kurse</a>
                <ul class="submenu">
                  <li><a routerLink='/service-five' routerLinkActive="active"
                      [routerLinkActiveOptions]="{exact: true}">Beikostkurs</a></li>
                </ul>
              </li>
              <li class="has-submemu">
                <a routerLink='/service-one' routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}">Leistungen</a>
                <ul class="submenu">
                  <li><a routerLink='/service-one' routerLinkActive="active"
                      [routerLinkActiveOptions]="{exact: true}">Schwangerschaft & Stillzeit</a></li>
                  <li><a routerLink='/service-two' routerLinkActive="active"
                      [routerLinkActiveOptions]="{exact: true}">Säuglingsernährung</a></li>
                  <li><a routerLink='/service-three' routerLinkActive="active"
                      [routerLinkActiveOptions]="{exact: true}">Kleinkindernährung</a></li>
                  <li><a routerLink='/service-four' routerLinkActive="active"
                      [routerLinkActiveOptions]="{exact: true}">Kühlschrankcheck</a></li>
                  <li><a routerLink='/service-six' routerLinkActive="active"
                      [routerLinkActiveOptions]="{exact: true}">Vorträge buchen</a></li>
                </ul>
              </li>
              <!--               <li class="has-submemu">
                <a routerLink='/blog-grid' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog</a>
                <ul class="submenu">
                  <li><a routerLink='/blog-standard' routerLinkActive="active"
                      [routerLinkActiveOptions]="{exact: true}">Blog Standard</a></li>
                  <li><a routerLink='/blog-grid' routerLinkActive="active"
                      [routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>
                  <li><a routerLink='/blog-detail' routerLinkActive="active"
                      [routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                </ul>
              </li> -->
              <!--               <li class="has-submemu">
                <a routerLink='/portfolio-one' routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}">Portfolio</a>
                <ul class="submenu">
                  <li><a routerLink='/portfolio-two' routerLinkActive="active"
                      [routerLinkActiveOptions]="{exact: true}">Portfolio Two</a></li>
                  <li><a routerLink='/portfolio-detail' routerLinkActive="active"
                      [routerLinkActiveOptions]="{exact: true}">Portfolio Details</a></li>
                </ul>
              </li> -->
              <!-- <li class="has-submemu">
                <a routerLink='/shop' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Shop</a>
                <ul class="submenu">
                  <li><a routerLink='/shop-detail' routerLinkActive="active"
                      [routerLinkActiveOptions]="{exact: true}">Product Details</a></li>
                </ul>
              </li>
              <li><a routerLink='/contact-us' routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}">Contact</a></li> -->
            </ul>
          </div>
          <!-- Pushed Item -->
          <div class="nav-pushed-item"></div>
        </div>

        <!-- Navbar Extra  -->
        <!-- Social Link -->
        <div class="widget social-link">
          <ul>
          </ul>
        </div>
        <!-- Navbar Toggler -->
        <div class="navbar-toggler">
          <span></span><span></span><span></span>
        </div>
      </div>

    </div>
  </div>
</header>
<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-box" class="ai2html">

  <!-- Artboard: Titel -->
  <div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Titel" class="g-artboard" style=""
    data-aspect-ratio="1.778" data-min-width="0">
    <div style="padding: 0 0 56.25% 0;"></div>
    <img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Titel-img" class="g-aiImg" alt=""
      src="assets/img/Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-Titel.jpg" />
  </div>
</div>