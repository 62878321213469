<app-header1></app-header1>
<!--====== Banner part start ======-->

<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-box" class="ai2html">



	<!-- Artboard: S_uglinge -->
	<div id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-S_uglinge" class="g-artboard" style=""
		data-aspect-ratio="1.778" data-min-width="0">
		<div style="padding: 0 0 56.25% 0;"></div>
		<img id="g-Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-S_uglinge-img" class="g-aiImg" alt=""
			src="assets/img/Website_Katharina-Richter_Elternkindern_hrung_Stand-20_01-S_uglinge.jpg" />
		<div id="g-ai8-1" class="g-Ebene_1 g-aiAbs"
			style="top:-1.2037%;left:50.024%;margin-left:-25.2344%;width:50.4687%;">
			<p class="g-pstyle0">Säuglingsernährung</p>
		</div>
		<div id="g-ai8-2" class="g-Ebene_1 g-aiAbs" style="top:30.8333%;left:20.868%;width:66.0417%;">
			<p class="g-pstyle1 display-none">Was? Wann? Wie?</p>
			<p class="display-none">Die wohl häufigsten Fragen rund um die Säuglingsernährung und genau dabei möchte ich
				dir Sicherheit im Alltag geben.</p>
			<p class="display-none">Die Beratung und Ernährungsumstellung orientiert sich an der Entwicklung und den
				Bedürfnissen des Kindes.</p>
			<p class="display-none">&nbsp;</p>
			<p class="display-none">&nbsp;</p>
			<p class="g-pstyle2">Mögliche Beratungsthemen: </p>
			<p>&nbsp;</p>
			<p>- Stillen, Pre-Nahrung, Folgenahrung</p>
			<p>- Beikosteinführung: Brei, Fingerfood oder beides? Selbstgekocht oder lieber
				Gläschen?</p>
			<p>- Allergieprävention</p>
			<p class="display-none480">- Umstieg Familienkost sowie geeignete Lebensmittelauswahl</p>
			<p class="display-none768 display-none600">- spezielle Ernährungsformen, wie vegetarisch, vegan etc.</p>
			<p class="display-none768 display-none600">- Ernährung bei Unverträglichkeiten wie Fructose,
				Lactoseintoleranz, Milcheiweißunverträglichkeit</p>
			<p>- Beschwerden, wie Verstopfungen, Blähungen, Durchfall etc. . </p>
			<p class="g-pstyle2"><span class="g-cstyle0">- </span>...vieles mehr </p>
		</div>
		<div id="g-ai8-3" class="g-Ebene_1 g-aiAbs g-aiPointText"
			style="top:84.217%;margin-top:-65.5px;left:28.8378%;width:871px;">
			<p class="g-pstyle3 display-none768 display-none600">Individuelle Einzelberatung inklusive Anamnese &
				Auswertung Ernährungsprotokoll</p>
			<p class="g-pstyle3">Erstberatungsdauer: 60 Minuten</p>
			<p class="g-pstyle3">Folgeberatungsdauer: 45 Minuten</p>
			<p class="g-pstyle3">Beratungshonorar: nur ab 87&euro;</p>
		</div>
		<div id="g-ai8-4" class="g-Ebene_1 g-aiAbs"
			style="top:88.5185%;left:73.3177%;margin-left:-6.5885%;width:13.1771%;">
			<a class="g-pstyle4" routerLink='/contactform' routerLinkActive="active"
				[routerLinkActiveOptions]="{exact: true}">Termin anfragen</a>
		</div>
	</div>
</div>