import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { tap } from 'rxjs/operators';
import { ContactformService } from '../services/contact-form.service';

interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-contactform',
  templateUrl: './contactform.component.html',
  styleUrls: ['./contactform.component.css']
})
export class ContactformComponent implements OnInit {
  public formData: FormGroup;
  public CLOSE_ON_SELECTED = false;
  public init = new Date();
  public resetModel = new Date(0);
  public model = [
    new Date(Date.now() + (3600 * 1000 * 24))
  ];

  @ViewChild('picker', { static: true }) _picker: MatDatepicker<Date>;

  public kurse: Food[] = [
    { value: 'Beikostkurs', viewValue: 'Beikostkurs' },
    { value: 'Schwangerschaft', viewValue: 'Ernährungsberatung Schwangerschaft' },
    { value: 'Stillzeit', viewValue: 'Ernährungsberatung Stillzeit' },
    { value: 'Kleinkind', viewValue: 'Ernährungsberatung Kleinkind' },
    { value: 'Kurzberatung', viewValue: 'Kurzberatung' },
    { value: 'Einkaufstraining', viewValue: 'Einkaufstraining' },
    { value: 'Kühlschrankcheck', viewValue: 'Kühlschrankcheck' },
    { value: 'vortraege', viewValue: 'Vorträge' }

  ];

  public showSuccess: Boolean = false;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Germany];

  constructor(private builder: FormBuilder, private contact: ContactformService) { }

  ngOnInit() {
    this.formData = this.builder.group({
      Fullname: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      Email: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
      Comment: new FormControl('', [Validators.required]),
      kurs: new FormControl('', [Validators.required])

    });
  }


  onSubmit(FormData) {
    console.log(FormData)
    console.log(this.model)
    this.contact.PostMessage({ form: FormData, date: this.model })
      .subscribe(response => {
        this.showSuccess = true;
      }, error => {
        console.warn(error.responseText)
        console.log({ error })
      })
  }

  public dateChanged(event: MatDatepickerInputEvent<Date>): void {
    if (event.value) {
      const date = event.value;
      const index = this._findDate(date);
      if (index === -1) {
        this.model.push(date);
      } else {
        this.model.splice(index, 1)
      }
      this.resetModel = new Date(0);
      if (!this.CLOSE_ON_SELECTED && this._picker !== undefined) {
        const closeFn = this._picker.close;
        this._picker.close = () => { };
        this._picker['_popupComponentRef'].instance._calendar.monthView._createWeekCells()
        setTimeout(() => {
          this._picker.close = closeFn;
        });
      }
    }
  }

  public remove(date: Date): void {
    const index = this._findDate(date);
    this.model.splice(index, 1)
  }

  weekendsDatesFilter = (d: Date): boolean => {
    const day = new Date(d).getDay();

    /* Prevent Saturday and Sunday for select. */
    return day !== 0 && day !== 6;
  }

  private _findDate(date: Date): number {
    return this.model.map((m) => +m).indexOf(+date);
  }
}

